<template>
  <div class="mini_game_bet">
    <sub-title>네임드 Speed 사다리</sub-title>
    <leisure-game-links></leisure-game-links>
    <leisure-named-links></leisure-named-links>
    <div class="mini_game">
      <leisure-game-ifram-view>
        <iframe id="gamefram" scrolling="no" frameborder="0"
                src="http://named.com/games/speedladder/view.php"></iframe>
      </leisure-game-ifram-view>
      <div class="game_tab">
        <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
        <leisure-timer-comp
            ref="ltimer"
            :leisure-game-info="leisureGameInfo"
            @timerStop="timerStop" @refreshTimer="refreshTimer">
          <span slot="title" class="text-orange">네임드 스피드 사다리</span>
        </leisure-timer-comp>

        <!--선택부분-->
        <div class="mini_bet_box">
          <div class="mg">
            <span class="t">홀/짝</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN,leisureGameInfo.leisureGame.id,1, oddevenConfig.odds1, oddevenConfig, '홀')">
              <div class="r">
                <span class="n text_color01">홀</span>
                <span class="b">{{ oddevenConfig.odds1 }}</span>
              </div>
            </div>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN && selectedInfo.selectedWay === 2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN,leisureGameInfo.leisureGame.id,2,  oddevenConfig.odds2, oddevenConfig, '짝')">
              <div class="r">
                <span class="n text_color02">짝</span>
                <span class="b">{{ oddevenConfig.odds2 }}</span>
              </div>
            </div>
          </div>
          <div class="mg">
            <span class="t">좌/우 출발</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,1, leftrightConfig.odds1, leftrightConfig, '좌출발')">
              <div class="r">
                <span class="n text_color01">좌</span>
                <span class="b">{{ leftrightConfig.odds1 }}</span>
              </div>
            </div>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT && selectedInfo.selectedWay === 2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT,leisureGameInfo.leisureGame.id,2, leftrightConfig.odds2, leftrightConfig, '우출발')">
              <div class="r">
                <span class="n text_color02">우</span>
                <span class="b">{{ leftrightConfig.odds2 }}</span>
              </div>
            </div>
          </div>
          <div class="mg">
            <span class="t">3줄/4줄</span>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT && selectedInfo.selectedWay === 1}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT,leisureGameInfo.leisureGame.id,1, lineConfig.odds1, lineConfig, '3줄')">
              <div class="r">
                <span class="n text_color01">3줄</span>
                <span class="b">{{ lineConfig.odds1 }}</span>
              </div>
            </div>
            <div class="mg_btn b2"
                 :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT && selectedInfo.selectedWay === 2}"
                 @click="selected(leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT,leisureGameInfo.leisureGame.id,2, lineConfig.odds2, lineConfig, '4줄')">
              <div class="r">
                <span class="n text_color02">4줄</span>
                <span class="b">{{ lineConfig.odds2 }}</span>
              </div>
            </div>
          </div>

        </div>

        <!--파워볼 베팅카트-->
        <leisure-bet-cart-comp
            ref="betCart" :kind="kind"
            :selected-info="selectedInfo">
          S 사다리
        </leisure-bet-cart-comp>

        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>
    </div>
  </div>
</template>

<script>
import LeisureTimerComp from "@/views/afront/leisure/LeisureTimerComp";
import LeisureBetCartComp from "@/views/afront/leisure/LeisureBetCartComp";
import LeisureBetMasker from "@/views/afront/leisure/LeisureBetMasker";
import LeisureLatelyBetListComp from "@/views/afront/leisure/LeisureLatelyBetListComp";
import LeisureGameIframView from "@/views/afront/leisure/LeisureGameIframView";

import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "@/store/mutation-types";
import leisureConst from "@/common/leisureConst";
import {leisureGameMixin} from "@/common/mixin";
import {getLeisureGameAttributeConfig} from "@/network/leisureRequest";

export default {

  name: "NamedSpeedSadali",
  mixins: [leisureGameMixin],
  components: {
    LeisureGameIframView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp,
  },
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_NAMED_SPEEDSADALI,
    }
  },

  methods: {
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.oddevenConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_ODDEVEN)
          })

          this.leftrightConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LEFT_RIGHT)
          })

          this.lineConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_NAMEDSPEEDSADALI_LINE_COUNT)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
  },
}
</script>

<style scoped>

</style>